import React from "react"

// import { Button } from "react-bootstrap"
// import WhoAreWeModal from "./who-are-we"

import { Link } from "gatsby"

const WhoAreWeSection = () => {
  // const [modalShow, setModalShow] = useState(false)

  return (
    <>
      <div className="about-content about-section" id="about">
        <h2 className="mt-3 mb-2">Who We Are</h2>
        <div className="heading-line2 mx-auto"></div>
        <p className="mt-3">
          JBL Sourcing is a specific, specially designed Medtech industry search
          and consulting firm that identifies, locates, and evaluates the
          highest performers for potential opportunities across the U.S. Our
          clients range across every industry and specialty niche within medical
          technology and pharmaceuticals, integrating us within the most
          comprehensive network of professionals.
        </p>
        {/* <Button
          onClick={() => setModalShow(true)}
          className="hvr-icon-forward read-more"
          to="/"
        >
          READ MORE<i className="fa fa-chevron-right ml-2 hvr-icon"></i>
        </Button> */}
        <Link to="/about-us" className="hvr-icon-forward btn-global">
          READ MORE<i className="fa fa-chevron-right ml-2 hvr-icon"></i>
        </Link>
        {/* <WhoAreWeModal show={modalShow} onHide={() => setModalShow(false)} /> */}
      </div>
    </>
  )
}

export default WhoAreWeSection

import React, { useState } from "react"

import { Button } from "react-bootstrap"
import TheDifferenceModal from "./the-difference"

const TheDifferenceSection = () => {
  const [modalShow, setModalShow] = useState(false)

  return (
    <>
      <div className="container-fluid section-2 overflow-hidden">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-5 p-relative">
              <div className="sec-2-contents">
                <h2 className="mt-0 mb-2">The Difference</h2>
                <div className="heading-line2" />
                <p className="mt-3">
                  JBL Sourcing thrives in a crowded search market. We track top
                  candidates where they're supposed to be - working hard and
                  contributing - more concerned with their next project than
                  their next job.
                </p>
                <Button
                  onClick={() => setModalShow(true)}
                  className="hvr-icon-forward read-more"
                >
                  READ MORE
                  <i className="fa fa-chevron-right ml-2 hvr-icon" />
                </Button>
              </div>
              <div className="dot-svg-2" />
              <TheDifferenceModal
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TheDifferenceSection

import React, { useState } from "react"

import { Button } from "react-bootstrap"
import CareerPathModal from "./career-path"

const CareerPathSection = () => {
  const [modalShow, setModalShow] = useState(false)

  return (
    <>
      <div className="container-fluid section-3 overflow-hidden">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-7" />
            <div className="col-12 col-lg-5 p-relative">
              <div className="sec-2-contents">
                <h2 className="mt-0 mb-2">Career Path Development</h2>
                <div className="heading-line2" />
                <p className="mt-3">
                  We have access to information in the market that can help
                  point you in the right direction.
                </p>
                <Button
                  onClick={() => setModalShow(true)}
                  className="hvr-icon-forward read-more"
                >
                  READ MORE
                  <i className="fa fa-chevron-right ml-2 hvr-icon" />
                </Button>
              </div>
              <div className="dot-svg-3" />
              <CareerPathModal
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CareerPathSection

import React from "react"

import {
  Modal,
  // ModalTitle,
  // ModalHeader,
  // ModalBody,
  // ModalFooter,
  // Button,
} from "react-bootstrap"

const TheDifferenceModal = props => {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton style={{ border: "none" }} />
        <Modal.Body style={{ padding: "3rem" }}>
          <div className="heading-line2 mx-auto mb-3" />
          <p>
            Our approach differs from other recruiters in that we never approach
            candidates to pitch a job. Selling or coercing does not result in
            success. We look to first understand readiness in making a career
            change, then schedule a meeting to talk about strengths and
            candidate wish list. This process results in making exceptionally
            precise matches for our clients.
          </p>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer> */}
      </Modal>
    </>
  )
}

export default TheDifferenceModal

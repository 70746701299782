import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MySwiper from "../components/swiper/swiper"
import SampleForm from "../components/form/form"
// import { LazyLoadImage } from "react-lazy-load-image-component"
// import "react-lazy-load-image-component/src/effects/blur.css"

import executiveSearch from "../images/excutive-search.svg"
import consultingService from "../images/consulting-services.svg"
import consulting from "../images/consulting.svg"

import searchAssessment from "../images/search-assessment.svg"
import noSelling from "../images/no-selling.svg"
import candidateProfile from "../images/candidate-profile.svg"
import referralsPrecission from "../images/referrals-precision.svg"
import inari from "../images/inari.png"
import edward from "../images/edwards.png"
import olympus from "../images/olympus.png"
import spr from "../images/spr.jpg"
import mainstay from "../images/mainstay.jpg"

import partnersImg from "../images/handshake-review.jpg"

import TheDifferenceSection from "../components/modal/the-difference/td-section"
import CareerPathSection from "../components/modal/career-path/cpd-section"
import WhoAreWeSection from "../components/modal/who-are-we/au-section"

class IndexPage extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description
    const posts = this.props.data.allSanityPost.edges
    console.log(posts)
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="JBL Sourcing" description={siteDescription} />

        <div className="container-fluid hero-section overflow-hidden">
          <div className="container">
            <div className="brandstamp text-white">
              <h1>High Quality, Precision Executive Search</h1>
              <Link to="/#contact" className="btn-global" type="button">
                <i className="fa fa-phone mr-2"></i>CONTACT US
              </Link>
            </div>
          </div>
        </div>

        <div className="container-fluid svc-section text-center">
          <div className="container">
            <div className="row text-center svc-row p-relative">
              <div className="col-lg-4 svc-container">
                <img src={executiveSearch} alt="Executive Search" />
                <h4 className="font-weight-bold mt-2 mb-3">
                  Executive
                  <br />
                  Search
                </h4>
                <div className="link-wrapper">
                  <Link
                    className="svc-link p-relative hvr-underline-from-center pb-2 text-decoration-none"
                    to="/executive-search"
                  >
                    LEARN MORE
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 svc-container">
                <img
                  src={consultingService}
                  alt="Organizational Consultation"
                />
                <h4 className="font-weight-bold mt-2 mb-3">
                  Organizational
                  <br />
                  Consultation
                </h4>
                <div className="link-wrapper">
                  <Link
                    className="svc-link p-relative hvr-underline-from-center pb-2 text-decoration-none"
                    to="/organizational-consultation"
                  >
                    LEARN MORE
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 svc-container">
                <img src={consulting} alt="Data Partnership" />
                <h4 className="font-weight-bold mt-2 mb-3">
                  Data
                  <br />
                  Partnership
                </h4>
                <div className="link-wrapper">
                  <Link
                    className="svc-link p-relative hvr-underline-from-center pb-2 text-decoration-none"
                    to="/data-partnership"
                  >
                    LEARN MORE
                  </Link>
                </div>
              </div>
            </div>
            <WhoAreWeSection />
          </div>
        </div>

        <TheDifferenceSection />

        <CareerPathSection />

        <div
          className="container-fluid why-us-section overflow-hidden"
          id="why-us"
        >
          <div className="container text-center text-white">
            <div className="why-us-content">
              <h2>Why Us</h2>
              <div className="heading-line2 mx-auto"></div>
              <p className="mt-3">
                Identifying and qualifying the most advanced performers in the
                world of MedTech calls for a complementary, forefront
                approach.We offer services for medical device professionals,
                both candidates and clients the same, that you won't get from
                broader search firms.
              </p>
            </div>
          </div>
        </div>

        {/* <div className="container-fluid section-4 overflow-hidden">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-5 p-relative">
                <div className="sec-2-contents">
                  <h2 className="mt-0 mb-2">The Difference</h2>
                  <div className="heading-line2"></div>
                  <p className="mt-3">
                    JBL Sourcing thrives in a crowded search market. We track
                    top candidates where they're supposed to be - working hard
                    and contributing - more concerned with their next project
                    than their next job.
                  </p>
                  <Link className="hvr-icon-forward read-more" to="/">
                    READ MORE
                    <i className="fa fa-chevron-right ml-2 hvr-icon"></i>
                  </Link>
                </div>
                <div className="dot-svg-2"></div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="client-section" id="clients">
          <div className="container vp-section overflow-hidden">
            <h2 className="text-center mb-3">Highest Quality Process</h2>
            <div className="heading-line2 mx-auto"></div>
            <div className="row mt-5">
              <div className="col-lg-5 offset-lg-1 text-center my-3">
                <div className="vp-content">
                  <img src={searchAssessment} alt="Search Assessment" />
                  <h5 className="mt-3">
                    <strong>Search Assessment</strong>
                  </h5>
                  <p>
                    Our MedTech focus allows us to consult on filling positions
                    in an unrivaled way. Most search firms will make general
                    assumptions based on job descriptions and checklists. Our
                    search intakes create technical matches that are based on
                    realistic needs.
                  </p>
                </div>
              </div>
              <div className="col-lg-5 text-center my-3">
                <div className="vp-content">
                  <img src={noSelling} alt="No Selling" />
                  <h5 className="mt-3">
                    <strong>No Selling or Coercing</strong>
                    <br />
                  </h5>
                  <p>
                    We don't discuss opportunities or positions with candidates
                    until we get to know them first. This allows us to get
                    better insight into their true wish lists and career
                    trajectory. Once we understand their ambitions, we can work
                    towards finding the perfect client-candidate match.
                  </p>
                </div>
              </div>
              <div className="col-lg-5 offset-lg-1 text-center my-3">
                <div className="vp-content">
                  <img src={candidateProfile} alt="Candidate Profile" />
                  <h5 className="mt-3">
                    <strong>Candidate Profile</strong>
                    <br />
                  </h5>
                  <p>
                    We build comprehensive, in-depth profiles that give clients
                    a true picture of the candidate. Our candidate evaluations
                    are honest. We will highlight the good and the bad. This
                    eliminates surprises all around. We do not rely on resumes
                    alone. Even the best of candidates can have weak resumes.
                  </p>
                </div>
              </div>
              <div className="col-lg-5 text-center my-3">
                <div className="vp-content">
                  <img src={referralsPrecission} alt="Referrals Precision" />
                  <h5 className="mt-3">
                    <strong>Referrals and Precision</strong>
                    <br />
                  </h5>
                  <p>
                    We don't rely on job boards like all the others. We do our
                    recruiting through a trusted network of referrals and
                    recommendations. We call the appropriate professionals who
                    are contributing and driving growth results to MedTech
                    companies. We then find out the variance between where they
                    are now and where they think they should be.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid partner-section">
          <div className="container">
            <h2 className="mb-0 mt-0">Our Valued Partners</h2>
            <div className="d-flex align-items-center mb-5">
              <div className="heading-line"></div>
              <p className="sub-heading mb-0 ml-2">CURRENT &amp; PAST</p>
            </div>
            <div className="row">
              <div className="col-lg-4 ">
                <img
                  className="w-100"
                  src={partnersImg}
                  alt="Our Valued Partners"
                />
              </div>
              <div className="col-lg-8 pt-3 p-relative">
                <MySwiper slidesperview={1}>
                  <div className="row justify-content-center">
                    <div className="col-12 col-lg-10">
                      <div className="pt-3">
                        <div className="review-item ">
                          <div className="review-statement ">
                            <h3 className="mt-0 mb-3">
                              Global Leader, Healthcare Economics
                            </h3>
                            <p>
                              <em>
                                I have found the recruiting services provided by
                                Justin Ball to be an indispensable tool in
                                building a solid team. Within the highly
                                specialized world of medical devices it can be
                                quite difficult to find individuals who not only
                                have the technical skills required for the job,
                                but the right personality and cultural fit as
                                well. Both are requirements for long term
                                success within my company. Justin has done a
                                great job at finding the blend we are looking
                                for, and we certainly hope to continue our key
                                partnership moving forward into the future.
                              </em>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-center">
                    <div className="col-12 col-lg-10">
                      <div className="pt-3">
                        <div className="review-item ">
                          <div className="review-statement">
                            <h3 className="mt-0 mb-3">
                              VP Health Economics and Reimbursement
                            </h3>
                            <p>
                              <em>
                                I built my entire team working with Justin Ball
                                to supply candidates. Justin initially took time
                                to learn the specifics of my needs and then
                                provided well vetted candidates for interviews.
                                He was extremely accessible and very attentive
                                to my needs. We were in a hurry to get the team
                                on board, and Justin’s sense of urgency was
                                fantastic. I will definitely be reaching out
                                again when I need to hire.
                              </em>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-center">
                    <div className="col-12 col-lg-10">
                      <div className="pt-3">
                        <div className="review-item ">
                          <div
                            className="review-statement"
                            style={{ left: "20px" }}
                          >
                            <h3 className="mt-0 mb-3">
                              National Account Manager
                            </h3>
                            <p>
                              <em>
                                This is a note in regard to the productive
                                interactions I experienced in working with
                                Justin Ball. As an Account Manager actively
                                looking for a new opportunity within the Market
                                Access space, I was able to connect with Justin
                                early on in my search. We immediately developed
                                a solid relationship that helped me to better
                                understand the opportunities that existed and if
                                my skill set would be an appropriate fit with
                                the positions he presented. All said, Justin’s
                                knowledgeable insight with several medical
                                device companies enabled me to be well prepared
                                prior to and during the interview process. In
                                turn I accepted a Market Access position with a
                                dynamic company that continues to meet my
                                expectations and challenge my skill set.
                              </em>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-center">
                    <div className="col-12 col-lg-10">
                      <div className="pt-3">
                        <div className="review-item ">
                          <div
                            className="review-statement"
                            style={{ left: "30px" }}
                          >
                            <h3 className="mt-0 mb-3">
                              Strategic Account Manager
                            </h3>
                            <p>
                              <em>
                                I built my entire team working with Justin Ball
                                to supply candidates. Justin initially took time
                                to learn the specifics of my needs and then
                                provided well vetted candidates for interviews.
                                He was extremely accessible and very attentive
                                to my needs. We were in a hurry to get the team
                                on board, and Justin’s sense of urgency was
                                fantastic. I will definitely be reaching out
                                again when I need to hire.
                              </em>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-center">
                    <div className="col-12 col-lg-10">
                      <div className="pt-3">
                        <div className="review-item ">
                          <div
                            className="review-statement"
                            style={{ left: "50px" }}
                          >
                            <h3 className="mt-0 mb-3">
                              Leader, U.S. Health Economics,{" "}
                              <span className="text-teal">Medical Devices</span>
                            </h3>
                            <p>
                              <em>
                                Justin is different from other recruiters, I get
                                calls from recruiters quite often and they
                                cannot wait to pitch the position to me. Justin
                                interviewed me for 30-45 minutes to make sure he
                                had the right position for me before even
                                pitching the opportunity. We both felt that the
                                position he currently was offering was not the
                                right fit but if he had one in the future he
                                would reach out. A few months later he called
                                again, and he nailed it. Justin placed me in a
                                position that was a perfect fit for me not just
                                any position. He took the time to understand my
                                personality and what drives me to wake up
                                everyday and placed me in a position where I
                                would have passion for work. I cannot thank him
                                enough.
                              </em>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-center">
                    <div className="col-12 col-lg-10">
                      <div className="pt-3">
                        <div className="review-item ">
                          <div
                            className="review-statement"
                            style={{ left: "80px" }}
                          >
                            <h3 className="mt-0 mb-3">
                              Director of Marketing,{" "}
                              <span className="text-teal">Medical Devices</span>
                            </h3>
                            <p>
                              <em>
                                Justin has been terrific to work with ever since
                                our first phone call. His follow up is fantastic
                                and he really cares about my individual
                                interests. Justin is extremely honest and has
                                always been upfront about if a potential job
                                aligns with my personal career goals. Some
                                recruiters just fill the job and move on but
                                Justin has maintained a great relationship with
                                me 4 years after placing me for a role in the
                                medical device industry. He understands my
                                future career goals and will frequently reach
                                out with ideas, tips, and job opportunities that
                                may help me achieve these goals! If you’re
                                looking for just a job call any recruiter but if
                                you’re looking to achieve more than just landing
                                a job and want to continue to rise up the
                                corporate ladder, call Justin!
                              </em>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-center">
                    <div className="col-12 col-lg-10">
                      <div className="pt-3">
                        <div className="review-item ">
                          <div
                            className="review-statement"
                            style={{ left: "80px" }}
                          >
                            <h3 className="mt-0 mb-3">
                              Marketing Manager,{" "}
                              <span className="text-teal">Medical Devices</span>
                            </h3>
                            <p>
                              <em>
                                During my recent job search, Justin found me and
                                reached out to get connected. After speaking
                                with him about my background and skill set, he
                                quickly got to work trying to find a mutually
                                beneficial fit for my next position. Within a
                                couple weeks he came back to me with a great
                                opportunity that otherwise wouldn’t have been on
                                my radar, and soon thereafter I began the
                                interviewing process which ultimately led to me
                                landing the job. Throughout this entire process
                                Justin was a consummate professional, expertly
                                balancing my needs with those of his client.
                              </em>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-center">
                    <div className="col-12 col-lg-10">
                      <div className="pt-3">
                        <div className="review-item ">
                          <div
                            className="review-statement"
                            style={{ left: "80px" }}
                          >
                            <h3 className="mt-0 mb-3">Director of Marketing</h3>
                            <p>
                              <em>
                                Justin has been a valuable partner for
                                identification and recruitment of strong talent
                                for my teams. He is exactly what you need in a
                                recruiter. He is diligent, thoughtful, and
                                deliberate with his choices. He then presents a
                                package to help you discern if this person is
                                the right fit. I would 100% recommend Justin to
                                help you with your need talent acquisition
                                needs.
                              </em>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-center">
                    <div className="col-12 col-lg-10">
                      <div className="pt-3">
                        <div className="review-item ">
                          <div
                            className="review-statement"
                            style={{ left: "80px" }}
                          >
                            <h3 className="mt-0 mb-3">
                              VP, Customer Solutions
                            </h3>
                            <p>
                              <em>
                                Justin is a professional, diligent, sincere and
                                results oriented recruitment expert who delivers
                                well screened and capable candidates for your
                                specific business needs. I've had the pleasure
                                of working with Justin on several occasions to
                                find classically trained yet entrepreneurial
                                focused people tailored for the job. He has
                                predictably presented great candidates that
                                became stellar, high performing employees in
                                sales, business development and technical
                                positions.
                              </em>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </MySwiper>
              </div>
            </div>
          </div>
        </div>

        <div className="client-section" id="clients">
          <div className="container vp-section overflow-hidden">
            <h2 className="text-center mb-3">A Few of Our Partners</h2>
            <div className="heading-line2 mx-auto"></div>
            <div className="row mt-5">
              <div className="col-md-3 mb-4">
                <div className="partners-content">
                  <a
                    href="https://www.ambuusa.com/"
                    target="_blank"
                    className="partners-url"
                    rel="noreferrer"
                  >
                    <img
                      src="/assets/ambu.png"
                      class="img-fluid"
                      alt="Ambuusa"
                    />
                  </a>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="partners-content">
                  <a
                    href="https://www.axogeninc.com/"
                    target="_blank"
                    className="partners-url"
                    rel="noreferrer"
                  >
                    <img
                      src="/assets/axogen.png"
                      class="img-fluid"
                      alt="Axogen"
                    />
                  </a>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="partners-content">
                  <a
                    href="https://www.centinelspine.com/"
                    target="_blank"
                    className="partners-url"
                    rel="noreferrer"
                  >
                    <img
                      src="/assets/centinel.png"
                      class="img-fluid"
                      alt="Centinel Spine"
                    />
                  </a>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="partners-content">
                  <a
                    href="https://corelinksurgical.com/"
                    target="_blank"
                    className="partners-url"
                    rel="noreferrer"
                  >
                    <img
                      src="/assets/corelink.png"
                      class="img-fluid"
                      alt="CoreLink"
                    />
                  </a>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="partners-content">
                  <a
                    href="https://www.ecamedical.com/"
                    target="_blank"
                    className="partners-url"
                    rel="noreferrer"
                  >
                    <img src="/assets/eca.png" class="img-fluid" alt="ECA" />
                  </a>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="partners-content">
                  <a
                    href="https://www.reshapelifesciences.com/"
                    target="_blank"
                    className="partners-url"
                    rel="noreferrer"
                  >
                    <img
                      src="/assets/ReShape.png"
                      class="img-fluid"
                      alt="ReShape"
                    />
                  </a>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="partners-content">
                  <a
                    href="https://www.intersectent.com/"
                    target="_blank"
                    className="partners-url"
                    rel="noreferrer"
                  >
                    <img
                      src="/assets/intersect.png"
                      class="img-fluid"
                      alt="Intersect"
                    />
                  </a>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="partners-content">
                  <a
                    href="https://www.microaire.com/"
                    target="_blank"
                    className="partners-url"
                    rel="noreferrer"
                  >
                    <img
                      src="/assets/microaire.png"
                      class="img-fluid"
                      alt="Microaire"
                    />
                  </a>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="partners-content">
                  <a
                    href="https://www.microlinesurgical.com/"
                    target="_blank"
                    className="partners-url"
                    rel="noreferrer"
                  >
                    <img
                      src="/assets/microline.png"
                      class="img-fluid"
                      alt="Microline"
                    />
                  </a>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="partners-content">
                  <a
                    href="https://www.stryker.com/"
                    target="_blank"
                    className="partners-url"
                    rel="noreferrer"
                  >
                    <img
                      src="/assets/stryker.png"
                      class="img-fluid"
                      alt="Stryker"
                    />
                  </a>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="partners-content">
                  <a
                    href="https://www.steris.com/"
                    target="_blank"
                    className="partners-url"
                    rel="noreferrer"
                  >
                    <img
                      src="/assets/steris.png"
                      class="img-fluid"
                      alt="Steris"
                    />
                  </a>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="partners-content">
                  <a
                    href="https://www.goremedical.com/"
                    target="_blank"
                    className="partners-url"
                    rel="noreferrer"
                  >
                    <img src="/assets/gore.png" class="img-fluid" alt="Gore" />
                  </a>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="partners-content">
                  <a
                    href="https://www.inarimedical.com"
                    target="_blank"
                    className="partners-url"
                    rel="noreferrer"
                  >
                    <img src={inari} class="img-fluid" alt="Inari" />
                  </a>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="partners-content">
                  <a
                    href="https://www.edwards.com/"
                    target="_blank"
                    className="partners-url"
                    rel="noreferrer"
                  >
                    <img src={edward} class="img-fluid" alt="Inari" />
                  </a>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="partners-content">
                  <a
                    href="https://medical.olympusamerica.com/"
                    target="_blank"
                    className="partners-url"
                    rel="noreferrer"
                  >
                    <img src={olympus} class="img-fluid" alt="Inari" />
                  </a>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="partners-content">
                  <a
                    href="https://www.sprtherapeutics.com/"
                    target="_blank"
                    className="partners-url"
                    rel="noreferrer"
                  >
                    <img src={spr} class="img-fluid" alt="SPR" />
                  </a>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="partners-content">
                  <a
                    href="https://mainstaymedical.com/"
                    target="_blank"
                    className="partners-url"
                    rel="noreferrer"
                  >
                    <img src={mainstay} class="img-fluid" alt="Mainstay" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contact-section" id="contact">
          <div className="container-fluid contact-wrapper">
            <div className="container">
              <div className="row justify-content-center text-center">
                <div className="col-lg-5">
                  <div className="contact-form mt-0">
                    <h2 className="mt-0 mb-2 text-white">Contact Us</h2>
                    <div className="heading-line2 mb-4 mx-auto"></div>
                    <a href="tel:3038185715">
                      <p className="mb-5">
                        <i className="fa fa-phone mr-2"></i>303-818-5715
                      </p>
                    </a>
                    <SampleForm
                      form_name="Newsletter Form"
                      form_id="cc4057be-af4a-4dbc-8e43-a65bdb0e8c94"
                      form_className="form-newsletter"
                      data-thankyou-url="/"
                    >
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          id="firstName"
                          name="Full Name"
                          required=""
                        />
                        <label htmlFor="firstName">Full Name</label>
                      </div>

                      <div className="form-group">
                        <input
                          className="form-control"
                          type="email"
                          id="homeEmail"
                          required=""
                          name="Email Address"
                        />
                        <label htmlFor="homeEmail">Email address</label>
                      </div>
                      <div className="form-group">
                        <input
                          className="form-control"
                          id="subject"
                          type="text"
                          name="Subject"
                        />
                        <label htmlFor="phone">Subject</label>
                      </div>
                      <div className="form-group">
                        <textarea
                          className="form-control h-auto"
                          id="message"
                          required=""
                          style={{ color: "#fff" }}
                          name="Message"
                        />
                        <label htmlFor="message">Message</label>
                      </div>
                      <div className="form-group">
                        <div className="webriq-recaptcha" />
                      </div>
                      <div className="form-group mb-4">
                        <button
                          className="btn-global contact-btn"
                          type="submit"
                        >
                          Send Message
                        </button>
                      </div>
                    </SampleForm>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid contactinfo-section">
          <div className="container">
            <div className="row">
              <div className="col-md-4 text-white text-left">
                <div className="contact-info-container">
                  <a href="tel:3038185715">
                    <p>
                      <i className="fa fa-phone mr-2"></i>3038185715
                    </p>
                  </a>
                </div>
              </div>
              <div className="col-md-4 text-white text-left">
                <div className="contact-info-container">
                  <a href="mailto:info@jblsourcing.com">
                    <p>
                      <i className="fa fa-envelope mr-2"></i>
                      info@jblsourcing.com
                    </p>
                  </a>
                </div>
              </div>
              <div className="col-md-4 text-white text-left">
                <div className="contact-info-container">
                  <p>
                    <i className="fa fa-clock-o mr-2"></i>Mon- Fri 8:00 - 5:00
                    PM
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="reviews-section">
          <div className="container">
            <div className="section-heading text-center">
              <h6 className="font-weight-bold text-uppercase flair">Reviews</h6>
              <h2>What Our Clients Say</h2>
            </div>
          </div>
          <MySwiper slidesperview={1}>
            <div className="row justify-content-center">
              <div className="col-8 mx-auto">
                <div className="pt-5">
                  <div className="review-item text-center">
                    <div className="review-statement bg-light">
                      <p className="text-center">
                        Sed ut perspiciatis unde omnis iste natus error sit
                        voluptatem accusantium doloremque laudantium, totam rem
                        aperiam, eaque ipsa quae ab illo inventore veritatis et
                        quasi architecto beatae vitae dicta sunt explicabo.
                      </p>
                    </div>
                    <div className="pt-5">
                      <span className="stars">
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                      </span>
                      <h4 className="mb-0">Jane Doe</h4>
                      <span className="text-muted">CEO, Company.inc</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-8 mx-auto">
                <div className="pt-5">
                  <div className="review-item text-center">
                    <div className="review-statement bg-light">
                      <p className="text-center">
                        Sed ut perspiciatis unde omnis iste natus error sit
                        voluptatem accusantium doloremque laudantium, totam rem
                        aperiam, eaque ipsa quae ab illo inventore veritatis et
                        quasi architecto beatae vitae dicta sunt explicabo.
                      </p>
                    </div>
                    <div className="pt-5">
                      <span className="stars">
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                      </span>
                      <h4 className="mb-0">Jane Doe</h4>
                      <span className="text-muted">CEO, Company.inc</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-8 mx-auto">
                <div className="pt-5">
                  <div className="review-item text-center">
                    <div className="review-statement bg-light">
                      <p className="text-center">
                        Sed ut perspiciatis unde omnis iste natus error sit
                        voluptatem accusantium doloremque laudantium, totam rem
                        aperiam, eaque ipsa quae ab illo inventore veritatis et
                        quasi architecto beatae vitae dicta sunt explicabo.
                      </p>
                    </div>
                    <div className="pt-5">
                      <span className="stars">
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                      </span>
                      <h4 className="mb-0">Jane Doe</h4>
                      <span className="text-muted">CEO, Company.inc</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </MySwiper>
        </div>

        <div className="contact-section">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-md-5">
                <div className="section-heading text-left">
                  <h6 className="font-weight-bold text-uppercase text-left flair">
                    Connect
                  </h6>
                  <h2>Get in Touch with Us</h2>
                  <p className="py-4">
                    Contact us using the following details below, or fill up the
                    form and we'll get back to you shortly.
                  </p>
                  <ul className="list-unstyled contact-details">
                    <li className="phone">
                      <a
                        className="link-unstyled"
                        href="tel:+1 234 5678 900"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        +1 234 5678 900
                      </a>
                    </li>
                    <li className="email">
                      <a
                        className="link-unstyled"
                        href="mailto:info@yourdomain.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        info@yourdomain.com
                      </a>
                    </li>
                    <li className="address">
                      22 Street Name, City Name, United States 1234
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="py-4">
                  <SampleForm
                    form_name="Newsletter Form"
                    form_id="5bcd42f86b63453b251972bc"
                    form_classname="form-newsletter"
                  >
                    <div className="form-row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            id="firstName"
                            required=""
                          />
                          <label htmlFor="firstName">First Name</label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            id="lastName"
                            required=""
                          />
                          <label htmlFor="lastName">Last Name</label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="email"
                        id="homeEmail"
                        required=""
                      />
                      <label htmlFor="homeEmail">Email address</label>
                    </div>
                    <div className="form-group">
                      <input className="form-control" id="phone" type="tel" />
                      <label htmlFor="phone">Phone (optional)</label>
                    </div>
                    <div className="form-group">
                      <textarea
                        className="form-control h-auto"
                        id="message"
                        required=""
                      />
                      <label htmlFor="message">Message</label>
                    </div>
                    <div className="form-group">
                      <div className="webriq-recaptcha" />
                    </div>
                    <div className="form-group mb-4">
                      <button className="btn btn-primary" type="submit">
                        Send Message
                      </button>
                    </div>
                  </SampleForm>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </Layout>
    )
  }
}

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityPost(sort: { order: ASC, fields: publishedAt }, limit: 3) {
      edges {
        node {
          id
          title
          mainImage {
            asset {
              fluid {
                src
              }
            }
          }
          slug {
            current
          }
          excerpt
          publishedAt(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
`

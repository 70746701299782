import React from "react"

import {
  Modal,
  // ModalTitle,
  // ModalHeader,
  // ModalBody,
  // ModalFooter,
  // Button,
} from "react-bootstrap"

const CareerPathModal = props => {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton style={{ border: "none" }} />
        <Modal.Body style={{ padding: "3rem" }}>
          <div className="heading-line2 mx-auto mb-3" />
          <p>
            We spend a large amount of time with candidates listening to the
            variance between where they are today professionally and where they
            believe they should be. Not only does this create a precise match
            for clients, it also allows us to target other opportunities that
            may exist in the market for our candidates.
          </p>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer> */}
      </Modal>
    </>
  )
}

export default CareerPathModal
